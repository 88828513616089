import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Box } from '@material-ui/core'
import font from '@helpers/font'
import { H1, BackgroundImage, MarkdownHandler, PageContainer } from '@system'
import classNames from 'classnames'
import get from 'lodash/get'
import makeBreakpointPadding from '@helpers/make-breakpoint-padding'

const { makeResponsiveFontSizes, pxToRem, adjustedStartValue } = font

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.white,
    fontSize: pxToRem(adjustedStartValue(30)),
    fontWeight: theme.typography.fontWeightLight,
    fontFamily: theme.typography.h1.fontFamily,
    letterSpacing: '-0.18px',
    lineHeight: 1.2,
    ...makeResponsiveFontSizes(40, 18),
  },
  markdownDescription: {
    display: 'block',
    color: theme.palette.common.white,
    letterSpacing: '-0.18px',
    lineHeight: 1.2,
    fontSize: theme.typography.h5.fontSize,
    [theme.breakpoints.down('sm')]: {
      padding: '15px 0px',
    },
  },
  description: {
    ...makeResponsiveFontSizes(22, 15),
    lineHeight: '27px',
  },
  backgroundImage: {
    width: '100%',
    minHeight: (props) =>
      props.blok.appLinks && props.blok.appLinks.length > 0 ? '550px' : '500px',
    height: '40vh',
    maxHeight: '900px',
    // backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      '&:before': {
        width: '100%',
        backgroundPosition: 'left !important',
        backgroundSize: '260% 100% !important',
      },
    },
  },
  contentBox: {
    position: 'relative',
    height: '100%',
    width: '35%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
  },
  ctaContainer: {
    paddingTop: '2rem',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    margin: '1rem 0 0.8rem',
    display: 'block',
  },
  outerContainer: {
    background: 'transparent',
    height: '100%',
  },
  icon: {
    margin: '40px 0 20px',
    '& .gatsby-image-wrapper': {
      height: '80px !important',
      maxWidth: '200px',
    },
    '& img': {
      objectPosition: 'left center !important',
    },
  },
  twoColumnLayout: {
    width: 'unset',
    paddingTop: '4rem',
    paddingBottom: '4rem',
  },
}))

const ShowcaseModule = (props) => {
  const { icon, title, description, cta, backgroundImage } = props.blok
  const classes = useStyles(props)
  const background = backgroundImage && get(backgroundImage, '[0].image')

  const BackgroundElement = (props) => {
    return background ? (
      <BackgroundImage className={classes.backgroundImage} image={background}>
        {props.children}
      </BackgroundImage>
    ) : (
      <Box className={classes.outerContainer}>{props.children}</Box>
    )
  }
  return (
    <SbEditable content={props.blok}>
      <PageContainer backgroundColor="transparent">
        <BackgroundElement>
          <Box
            className={classNames(classes.contentBox, {
              [classes.twoColumnLayout]: !!props.isTwoColumnLayout,
            })}
          >
            {icon && <Box className={classes.icon}>{renderBloks(icon)}</Box>}
            <H1 component="h2" className={classes.title}>
              {title}
            </H1>
            <MarkdownHandler
              className={classNames(classes.markdownDescription, {
                [classes.description]: description,
              })}
            >
              {description}
            </MarkdownHandler>

            <Box className={classes.ctaContainer}>
              <Typography className={classes.button}>
                {renderBloks(cta)}
              </Typography>
            </Box>
          </Box>
        </BackgroundElement>
      </PageContainer>
    </SbEditable>
  )
}

export default ShowcaseModule
